import { Component, OnInit } from '@angular/core';
import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ClassificationService } from 'src/app/services/classification/classification.service';
import { SetupService } from 'src/app/services/setup/setup.service';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ranks',
  templateUrl: './ranks.component.html',
  styleUrls: ['./ranks.component.scss']
})
export class RanksComponent implements OnInit {
  customClass = 'customClass';
  chevronUp = faChevronUp;
  chevronDown = faChevronDown;
  searchIcon = faSearch;
  selected: number;
  opened: boolean;
  // data: any;
  run = [];
  pursuers = [];
  late = [];
  searchText = '';
  searchTextChanged: Subject<string> = new Subject<string>();
  searchType = '';
  searchedUser: any;
  datas = [];
  isSearching = false;
  logoColor = '';
  userData: Observable<any>;
  error = '';
  textColor: string;
  conditionValue: any;
  isAgency = false;
  pdfLink: string;
  objects = [];
  classifications = []

  conditions = [
    { color: 'red', operator: 4, value: 500 },
    { color: 'blue', operator: 2, value: 700 },
    { color: 'black', operator: 3, value: 1000 },
    { color: 'yellow', operator: 3, value: 1500 },
    { color: 'orange', operator: 2, value: 1900 },
  ];

  constructor(private setupService: SetupService,
    private classifcationService: ClassificationService,
    private router: Router) {
    this.searchTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(
      model => {
        this.searchText = model;
        this.getUserPosition();
      }
    );
  }

  ngOnInit(): void {
    const params = this.router.url.split('/');
    const eventId = params[1];
    const classificationId = params[2];
    const userID = params[3];
    console.log(eventId, classificationId, userID);
    this.userData = this.setupService.getSetup(eventId).pipe(
      switchMap((res: any) => {
        this.setColors(res.setup.colors);
        return this.classifcationService.getClassificaiton(eventId, classificationId, userID).pipe(
          tap((res: any) => {
            this.pdfLink = res.pdf_url;
            this.conditions = res.color_conditions;
            if (res.current_user.agency) {
              this.isAgency = true;
            }
            if (res.accordion_present) {
              res.accordion_list.map(data => {
                // res[`${data}`]= res.participants.filter(user=> user.group.includes(data));
                // abc.push(`${data}` + res.participants.filter(user=> user.group.includes(data)));
                // this[`${data}`] = res.participants.filter(user=> user.group.includes(data));
                this.objects[`${data}`] = res.participants.filter(user => user.group.includes(data));
              })
            }
          }),
          map((data: any) => {
            data.participants.sort((a, b) => b.ranking_points - a.ranking_points);
            for (let i = 0; i < data.participants.length; i++) {
              data.participants[i].color = this.checkConditions(data.participants[i].ranking_points);
            }
            return data;
          }),
          tap((data: any) => {
            this.datas = data.participants
            // this.filterGroups(data.participants);
            console.log(data)
            console.log(this.datas)
            this.classifications = Object.entries(this.objects)
            console.log(this.classifications)
          }),
        );
      }),
      catchError(error => {
        console.log(error);
        this.error = 'Some error occured';
        return EMPTY;
      })
    );
  }

  checkConditions(value): any {
    for (let j = 0; j < this.conditions.length; j++) {
      if (this.conditions[j].operator == 0) { // >
        if (value > this.conditions[j].value) { return this.conditions[j].color; }
      } else if (this.conditions[j].operator == 1) { // >=
        if (value >= this.conditions[j].value) { return this.conditions[j].color; }
      } else if (this.conditions[j].operator == 2) { // <
        if (value < this.conditions[j].value) { return this.conditions[j].color; }
      } else if (this.conditions[j].operator == 3) { // <=
        if (value <= this.conditions[j].value) { return this.conditions[j].color; }
      } else if (this.conditions[j].operator == 4) { // =
        if (value === this.conditions[j].value) { return this.conditions[j].color; }
      }
    }
    return '';
  }

  clicked($event, a): void {
    this.opened = $event;
    this.isSearching = false;
    this.searchText = '';
    if (!this.opened && this.selected === a) {
      this.selected = 0;
      return;
    }
    this.selected = a;
  }


  setColors(colors): void {
    console.log(colors)
    colors.forEach(color => {
      if (color.name === 'background') {
        document.documentElement.style.setProperty('--background-color', color.color);
      }
      else if (color.name === 'textColorPrimary') {
        document.documentElement.style.setProperty('--text-color-primary', color.color);
      }
      else if (color.name === 'textColor') {
        document.documentElement.style.setProperty('--text-color', color.color);
        this.textColor = color.color;
      }
      else if (color.name === 'colorPrimary') {
        document.documentElement.style.setProperty('--primary-color', color.color);
        this.logoColor = color.color;
      }
    });
  }

  // filterGroups(data): void {
  //   this.run = data.filter(user => user.group.includes('fuga'));
  //   this.late = data.filter(user => user.group.includes('ritardo'));
  //   this.pursuers = data.filter(user => user.group.includes('seguitori'));
  // }

  onSearchTextUpdate(value: any, searchType: string): void {
    this.searchTextChanged.next(value);
    this.searchType = searchType;
  }

  searchTextUpdated(value) {
    this.searchTextChanged.next(value);
    this.searchType = 'all'
  }

  // getUserPosition(): void {
  //   if(this.searchType === 'all'){   
  //     console.log('eta')
  //     if(this.isAgency){
  //       this.searchedUser = this.datas.filter(agency=> agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
  //     }else{
  //       this.searchedUser =  this.datas.filter(user => user.name.toLowerCase().includes(this.searchText) ||
  //       user.surname.toLowerCase().includes(this.searchText));
  //     }
  //   }
  //   if (this.searchType === 'late') {
  //     if(this.isAgency){
  //         this.searchedUser = this.late.filter(agency=> agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
  //       }else{
  //         this.searchedUser =  this.late.filter(user => user.name.toLowerCase().includes(this.searchText) ||
  //         user.surname.toLowerCase().includes(this.searchText));
  //       }
  //     }
  //     else if (this.searchType === 'run') {
  //       if(this.isAgency){
  //         this.searchedUser = this.run.filter(agency=> agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
  //       }else{
  //       this.searchedUser = this.run.filter(user => user.name.toLowerCase().includes(this.searchText) ||
  //       user.surname.toLowerCase().includes(this.searchText));
  //       }
  //     }
  //     else if (this.searchType === 'pursuers') {
  //       if(this.isAgency){
  //         this.searchedUser = this.pursuers.filter(agency=> agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
  //       }else{
  //       this.searchedUser = this.pursuers.filter(user => user.name.toLowerCase().includes(this.searchText) ||
  //       user.surname.toLowerCase().includes(this.searchText));
  //       }
  //     }
  //     this.isSearching = true;
  // }

  getUserPosition(): void {
    if (this.searchType === 'all') {
      if (this.isAgency) {
        this.searchedUser = this.datas.filter(agency => agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
      } else {
        this.searchedUser = this.datas.filter(user => user.name.toLowerCase().includes(this.searchText) ||
          user.surname.toLowerCase().includes(this.searchText));
      }
    } else {
      const toFilter = this.classifications.find(classification=> classification[0] === this.searchType);
      if (this.isAgency) {
        this.searchedUser = toFilter[1].filter(agency => agency.denominazione_agenzia.toLowerCase().includes(this.searchText));
      } else {
        this.searchedUser = toFilter[1].filter(user => user.name.toLowerCase().includes(this.searchText) ||
          user.surname.toLowerCase().includes(this.searchText));
      }
    }
    this.isSearching = true;
  }

    // getGroup(group){
    //   if(group.includes('fuga')){
    //     return 'in fuga'
    //   }
    //   else if (group.includes('ritardo')) {
    //     return 'in ritardo'
    //   }
    //   else if(group.includes('inseguitori')){
    //     return 'inseguitori'
    //   }
    // }

    openPdf() {
      window.open(this.pdfLink)
    }
  }
