  <ng-container *ngIf="userData | async as data; else loading">
  <div class="image" style="background-image: url('/assets/cover.png')"></div>
  <div class="container">
    <div class="row">
      <i class="col-md-2 logo">
        <svg width="59px" height="63px" viewBox="0 0 59 63" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Group 5</title>
          <defs>
              <polygon id="path-1" points="0 0 59 0 59 63 0 63"></polygon>
          </defs>
          <g id="Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ViviCattolica-EventoDettaglio3" transform="translate(-421.000000, -686.000000)">
                  <g id="Group-5" transform="translate(421.000000, 686.000000)">
                      <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                      </mask>
                      <g id="Clip-2"></g>
                      <path d="M40.0357143,60.875 L56.8928571,60.875 L56.8928571,31.125 L40.0357143,31.125 L40.0357143,60.875 Z M2.10714286,60.875 L18.9642857,60.875 L18.9642857,48.125 L2.10714286,48.125 L2.10714286,60.875 Z M21.0714286,60.875 L37.9285714,60.875 L37.9285714,39.625 L21.0714286,39.625 L21.0714286,60.875 Z M57.9464286,29 L38.9821429,29 C38.400308,29 37.9285714,29.4757344 37.9285714,30.0625 L37.9285714,37.5 L20.0178571,37.5 C19.4360223,37.5 18.9642857,37.9757344 18.9642857,38.5625 L18.9642857,46 L1.05357143,46 C0.471736607,46 0,46.4757344 0,47.0625 L0,61.9375 C0,62.5242656 0.471736607,63 1.05357143,63 L57.9464286,63 C58.5282634,63 59,62.5242656 59,61.9375 L59,30.0625 C59,29.4757344 58.5282634,29 57.9464286,29 L57.9464286,29 Z" id="Fill-1" fill="#025273" mask="url(#mask-2)" [attr.fill]="logoColor"></path>
                      <path d="M52.9665255,11.0966617 C52.7325871,11.3363995 52.6262395,11.6731144 52.6800731,12.00376 L53.3266033,15.9686042 L50.0104309,14.1358038 C49.6927075,13.9601896 49.3069005,13.9601896 48.9890451,14.1358038 L45.6726088,15.969 L46.3190071,12.0041558 C46.3729726,11.6735102 46.2667569,11.3369273 46.0326866,11.0970575 L43.2454815,8.23986274 L47.0498502,7.65931983 C47.397921,7.60614738 47.6965124,7.38303418 47.8458741,7.06439528 L49.499804,3.54010396 L51.1537339,7.06373557 C51.3032275,7.38237447 51.601687,7.60548767 51.9497578,7.65866012 L55.7541264,8.23920304 L52.9665255,11.0966617 Z M58.945215,7.17166378 C58.8194715,6.79655389 58.494623,6.52303901 58.1036701,6.46326948 L52.8258722,5.6568426 L50.4553493,0.607306711 C50.2076886,0.079540425 49.578971,-0.147399078 49.0511912,0.100255252 C48.8283361,0.20501686 48.648891,0.384325455 48.5441267,0.607306711 L46.1746594,5.6568426 L40.8968615,6.46326948 C40.3205259,6.55101063 39.9244272,7.08933224 40.0121706,7.66565302 C40.0454207,7.88401633 40.1463586,8.08654664 40.3008661,8.2444807 L44.1510197,12.1905892 L43.2405996,17.7750162 C43.1478423,18.3505454 43.539191,18.8922975 44.1146029,18.9851843 C44.346958,19.0226557 44.5852505,18.98149 44.7914805,18.8680202 L49.499804,16.2654727 L54.2079955,18.8680202 C54.7182267,19.1501113 55.3605347,18.9651292 55.642501,18.4549112 C55.7570292,18.2477629 55.7985919,18.0080251 55.7605917,17.7743565 L54.8500397,12.1899295 L58.7004571,8.24382099 C58.9764859,7.96054244 59.0709585,7.54677367 58.945215,7.17166378 L58.945215,7.17166378 Z" id="Fill-3" fill="#025273" mask="url(#mask-2)" [attr.fill]="logoColor"></path>
                      <path d="M1.05555168,33 L12.6666202,33 C13.0664104,33 13.4318951,32.7741131 13.6106792,32.4165469 L17.5411578,24.5556317 L31.6665504,24.5556317 C32.0305838,24.5556317 32.3687562,24.3681403 32.5616583,24.059525 L37.8394167,15.6151567 C38.1484294,15.1207653 37.9980133,14.4696253 37.5037512,14.1606142 C37.0093572,13.8516031 36.3582138,14.0018865 36.049201,14.4962779 L31.0815109,22.4445396 L16.8888269,22.4445396 C16.4890367,22.4445396 16.1235519,22.6704264 15.9447679,23.0279926 L12.0142892,30.8889079 L1.05555168,30.8889079 C0.472623265,30.8889079 0,31.3615287 0,31.944454 C0,32.5273793 0.472623265,33 1.05555168,33" id="Fill-4" fill="#025273" mask="url(#mask-2)" [attr.fill]="logoColor"></path>
                  </g>
              </g>
          </g>
      </svg>
      </i>
      <div class="col-md-10" style="padding: 0px">
        <span class="text-lg"
          >{{ data?.current_user?.position }}° POSIZIONE
        </span>
        <span class="text text-center"
          >PUNTI {{ data?.current_user?.ranking_points }}</span
        >
      </div>
      <div class="col-md-12 text-center mt-2">
        <span class="text-lower">{{data?.note}}</span>
      </div>
    </div>
  </div>
  <div class="accordion-container">
    <ng-tempalte [ngTemplateOutlet]="data?.accordion_present? withAccordion : withoutAccordion"></ng-tempalte>
    <ng-template #withoutAccordion>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="Ricerca la tua posizione"
        style="border-right: 0px"
        [ngModel]="searchText"
        (ngModelChange)="searchTextUpdated($event)"
      />
      <div class="input-group-append">
        <span class="input-group-text" style="background-color: white"
          ><fa-icon [icon]="searchIcon"></fa-icon
        ></span>
      </div>
    </div>
    <div class="data" *ngIf="!isSearching">
      <div class="row list" *ngFor="let data of datas; let i= index"> 
        <div class="col-10">
          <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
          <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
            {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
          <p *ngIf="isAgency">{{data?.agency_code}}</p>
        </div>
        <div class="col-2">
          <h4 [ngStyle]="{'color': data?.color}">
            {{data?.ranking_points}}
          </h4>
        </div>
      </div>
    </div>
    <div class="data" *ngIf="isSearching">
      <div class="row list" *ngFor="let data of searchedUser"> 
        <div class="col-10">
          <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
          <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
            {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
          <p *ngIf="isAgency">{{data?.agency_code}}</p>
        </div>
        <div class="col-2">
          <h4 [ngStyle]="{'color': data?.color}">
            {{data?.ranking_points}}
          </h4>
        </div>
      </div>
    </div>
  </ng-template>
   <ng-template #withAccordion>
     <accordion [isAnimated]="true" [closeOthers]="true">
       <!-- <accordion-group
         [panelClass]="customClass"
         [isOpen]="true"
         (isOpenChange)="clicked($event, 1)"
       >
         <button
           class="btn btn-link btn-block clearfix"
           accordion-heading
           type="button"
         >
           <div class="pull-left float-left">Classifica in fuga</div>
           <span class="float-right pull-right"
             ><fa-icon
               [icon]="chevronUp"
               *ngIf="selected === 1 && opened"
             ></fa-icon>
             <fa-icon [icon]="chevronDown" *ngIf="selected !== 1"></fa-icon>
           </span>
         </button>
         <div class="input-group">
           <input
             type="text"
             class="form-control"
             placeholder="Ricerca la tua posizione"
             style="border-right: 0px"
             [ngModel]="searchText"
             (ngModelChange)="onSearchTextUpdate($event, 'run');"
           />
           <div class="input-group-append">
             <span class="input-group-text" style="background-color: white"
               ><fa-icon [icon]="searchIcon"></fa-icon
             ></span>
           </div>
         </div>
         <div class="data" *ngIf="!isSearching">
           <div class="row" *ngFor="let data of run; let i =index"> 
             <div class="col-10" *ngIf="i < 5">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2" *ngIf="i < 5">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
         <div class="data" *ngIf="isSearching">
           <div class="row" *ngFor="let data of searchedUser"> 
             <div class="col-10">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
       </accordion-group>
       <accordion-group
         [panelClass]="customClass"
         (isOpenChange)="clicked($event, 2)"
       >
         <button
           class="btn btn-link btn-block clearfix"
           accordion-heading
           type="button"
         >
           <div class="pull-left float-left">Classifica inseguitori</div>
           <span class="float-right pull-right"
             ><fa-icon
               [icon]="chevronUp"
               *ngIf="selected === 2 && opened"
             ></fa-icon>
             <fa-icon [icon]="chevronDown" *ngIf="selected != 2"></fa-icon
           ></span>
         </button>
         <div class="input-group">
           <input
             type="text"
             class="form-control"
             placeholder="Ricerca la tua posizione"
             style="border-right: 0px"
             [ngModel]="searchText"
             (ngModelChange)="onSearchTextUpdate($event, 'pursuers');"
           />
           <div class="input-group-append">
             <span class="input-group-text" style="background-color: white"
               ><fa-icon [icon]="searchIcon"></fa-icon
             ></span>
           </div>
         </div>
         <div class="data" *ngIf="!isSearching">
           <div class="row" *ngFor="let data of pursuers; let i =index"> 
             <div class="col-10" *ngIf="i < 5">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2" *ngIf="i < 5">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
         <div class="data" *ngIf="isSearching">
           <div class="row" *ngFor="let data of searchedUser"> 
             <div class="col-10">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
       </accordion-group>
       <accordion-group
         [panelClass]="customClass"
         (isOpenChange)="clicked($event, 3)"
       >
         <button
           class="btn btn-link btn-block clearfix"
           accordion-heading
           type="button"
         >
           <div class="pull-left float-left">Classifica in ritardo</div>
           <span class="float-right pull-right"
             ><fa-icon
               [icon]="chevronUp"
               *ngIf="selected === 3 && opened"
             ></fa-icon>
             <fa-icon [icon]="chevronDown" *ngIf="selected != 3"></fa-icon
           ></span>
         </button>
         <div class="input-group">
           <input
             type="text"
             class="form-control"
             placeholder="Ricerca la tua posizione"
             style="border-right: 0px"
             [ngModel]="searchText"
             (ngModelChange)="onSearchTextUpdate($event, 'late');"
             
           />
           <div class="input-group-append">
             <span class="input-group-text" style="background-color: white"
               ><fa-icon [icon]="searchIcon"></fa-icon
             ></span>
           </div>
         </div>
         <div class="data" *ngIf="!isSearching">
           <div class="row" *ngFor="let data of late; let i= index"> 
             <div class="col-10" *ngIf="i < 5">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2" *ngIf="i < 5">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
         <div class="data" *ngIf="isSearching">
           <div class="row" *ngFor="let data of searchedUser"> 
             <div class="col-10">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
       </accordion-group> -->
       <accordion-group *ngFor="let classification of classifications; let j = index"
         [panelClass]="customClass"
         [isOpen]="j === 0"
         (isOpenChange)="clicked($event, j); selected=j"
       >
         <button
           class="btn btn-link btn-block clearfix"
           accordion-heading
           type="button"
         >
           <div class="pull-left float-left"> {{classification[0]}}</div>
           <span class="float-right pull-right"
             ><fa-icon
               [icon]="chevronUp"
               *ngIf="selected === j && opened"
             ></fa-icon>
             <fa-icon [icon]="chevronDown" *ngIf="selected !== j || !opened"></fa-icon>
           </span>
         </button>
         <div class="input-group">
           <input
             type="text"
             class="form-control"
             placeholder="Ricerca la tua posizione"
             style="border-right: 0px"
             [ngModel]="searchText"
             (ngModelChange)="onSearchTextUpdate($event, classification[0]);"
           />
           <div class="input-group-append">
             <span class="input-group-text" style="background-color: white"
               ><fa-icon [icon]="searchIcon"></fa-icon
             ></span>
           </div>
         </div>
         <div class="data" *ngIf="!isSearching">
           <div class="row" *ngFor="let data of classification[1]; let i =index"> 
             <div class="col-10" *ngIf="i < 5">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2" *ngIf="i < 5">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
         <div class="data" *ngIf="isSearching">
           <div class="row" *ngFor="let data of searchedUser"> 
             <div class="col-10">
               <h5 style="font-weight: bold">{{data?.position}}° {{isAgency ? data?.denominazione_agenzia :data?.name}} {{isAgency ? '' : data?.surname}}</h5>
               <p *ngIf="!isAgency">{{data?.agency_code}} {{data?.agency_code ? ' - ' :''}} {{data?.denominazione_agenzia }}  {{data?.denominazione_agenzia ? ' - ' : '' }}
                 {{data?.zona }} {{data?.zone ? ' - ' : ''}}  {{data?.area}} {{data?.area ? ' - ' : ''}}  {{data?.distretto }}<p>
               <p *ngIf="isAgency">{{data?.agency_code}}</p>
             </div>
             <div class="col-2">
               <h4 [ngStyle]="{'color': data?.color}">
                 {{data?.ranking_points}}
               </h4>
             </div>
           </div>
         </div>
       </accordion-group>
     </accordion>
   </ng-template>
    
  </div>
  <!-- <div class="text-center" style="margin-bottom: 20px;" *ngIf="pdfLink">
    <button type="button" class="btn btna" (click)="openPdf()" style="margin-bottom: 20px; padding: 10px;">CONSULTA E SCARICA LA CLASSIFICA</button>
  </div> -->
</ng-container>
<ng-template #loading>
  <div class="text-center" style="padding: 30%" *ngIf="loading">
    <h1 style="margin: auto">Loading...</h1>
  </div>
</ng-template>
