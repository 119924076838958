import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
            setHeaders: {
                Authorization: `Token 767dffb9ffb6fb30a3dc7eb7e985e1f8`
            }
        });
    return next.handle(request);
}
}
